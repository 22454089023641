import React, { createContext, useContext, useState, useEffect } from 'react'
import * as db from 'firebase/firestore'

const StoreItemsContext = createContext()

export const StoreItemsProvider = ({ children }) => {
  const [ivystores, setIvystores] = useState([])
  const [items, setItems] = useState([])
  const [loadedItems, setLoadedItems] = useState({})
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    const queryIvystores = db.query(
      db.collection(db.getFirestore(), 'ivystores')
    )

    const queryItems = db.query(
      db.collection(db.getFirestore(), 'ivystore_items')
    )

    const unsubscribeItems = db.onSnapshot(queryItems, snapshot => {
      const state = snapshot.docs
        .map(doc => ({
          id: doc.id,
          ...doc.data()
        }))
        .sort((a, b) => b.created_at?.seconds - a.created_at?.seconds);

      setItems(state)
      setLoadedItems(state.map(item => ({ [item.id]: item })))
    });

    const unsubscribeIvystores = db.onSnapshot(queryIvystores, snapshot => {
      const state = snapshot.docs
        .map(doc => ({
          id: doc.id,
          ...doc.data()
        }))
        .sort((a, b) => b.created_at?.seconds - a.created_at?.seconds);

      setIvystores(state)
      setLoadedItems(state.map(item => ({ [item.id]: item })))
      setIsLoaded(true)
    });

    return () => {
      unsubscribeItems()
      unsubscribeIvystores()
    };
  }, [])

  const loadItem = async (id) => {
    if (loadedItems[id]) {
      return loadedItems[id]
    }

    const docRef = db.doc(db.getFirestore(), 'ivystore_items', id)
    const docSnap = await db.getDoc(docRef)

    if (docSnap.exists()) {
      const item = { id: docSnap.id, ...docSnap.data() }
      setLoadedItems(prev => ({ ...prev, [id]: item }))
      return item
    } else {
      console.error('No such document!')
      return null
    }
  }

  return (
    <StoreItemsContext.Provider value={{ items, ivystores, loadItem, isLoaded }}>
      {children}
    </StoreItemsContext.Provider>
  )
}

export const useIvystore = (storeId) => {
  const context = useContext(StoreItemsContext)
  if (!context) {
    throw new Error('useIvystore must be used within an StoreItemsProvider')
  }
  return context.ivystores.find(store => store.id === storeId)
}

export const useIvystoreInfo = (slug) => {
  const context = useContext(StoreItemsContext)
  if (!context) {
    throw new Error('useIvystoreInfo must be used within an StoreItemsProvider')
  }
  if (!context.isLoaded) {
    return undefined
  }
  return context.ivystores.find(store => store.slug === slug) || null
}

export const useIvystoreItems = () => {
  const context = useContext(StoreItemsContext)
  if (!context) {
    throw new Error('useIvystoreItems must be used within an StoreItemsProvider')
  }
  console.log('context.items', context.items)
  return context.items // user_id similar to storeId
}

export const useIvystoreItem = (id) => {
  const context = useContext(StoreItemsContext)
  if (!context) {
    throw new Error('useIvystoreItem must be used within an StoreItemsProvider')
  }

  return context.items.find(item => item.id === id)
}

export const useLoadIvystoreItem = (id) => {
  const context = useContext(StoreItemsContext)
  if (!context) {
    throw new Error('useLoadIvystoreItem must be used within an StoreItemsProvider')
  }
  return context.loadItem(id)
}
